import { HeadingImage } from '@expo/ui/src/images';
import { Flex, MainContentWrapper, Margin } from '@expo/ui/src/layout';
import { useChosenCharity } from '../../../traits/context/chosenCharity';
import { useChosenPublicCharity } from '../../../traits/context/chosenPublicCharity';
import { H2, P } from '@expo/ui/src/typography';
import { RangeInput } from '@expo/ui/src/inputs';
import { MainButton } from '@expo/ui/src/buttons';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useApi } from '../../../traits/hooks/useApi';
import { LoadingCircle } from '@expo/ui/src/loadingIndicators';
import { useState } from 'react';

export const VolunteerRating = () => {
  const { chosenCharity } = useChosenCharity();
  const { chosenCharity: chosenPublicCharity } = useChosenPublicCharity();
  const { ratingId, publicKey } = useParams();
  const navigate = useNavigate();
  const api = useApi();

  const [rating, setRating] = useState('6');

  const intRating = parseInt(rating);

  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      api.ratingControllerUpdateRating(ratingId ?? '', {
        rating: intRating,
      }),
    onSuccess: () =>
      navigate(`/${chosenCharity?.slug ?? chosenPublicCharity?.slug}/sign-confirmation?publicKey=${publicKey}`),
  });

  if (isLoading) {
    return (
      <MainContentWrapper>
        <Flex height="100%" width="100%" justify="center" align="center">
          <Margin $top="14xl" />
          <LoadingCircle />
        </Flex>
      </MainContentWrapper>
    );
  }

  return (
    <MainContentWrapper>
      <Flex justify="center" align="center">
        <HeadingImage src={chosenCharity?.logo.downloadUrl ?? chosenPublicCharity?.logo.downloadUrl} />
      </Flex>
      <Margin $top="7xl" />
      <H2>Ranka 1-10</H2>

      <Margin $top="5xl" />

      <RangeInput
        value={rating}
        onChange={val => setRating(val)}
        sliderThumbColor={chosenCharity?.bgColorHex ?? chosenPublicCharity?.bgColorHex}
        max={10}
        min={1}
      />
      <Flex flexDir="row">
        <Flex flex={1} align="flex-start">
          <P>Inte nöjd</P>
        </Flex>
        <Flex flex={1} align="center">
          <P>Ganska nöjd</P>
        </Flex>
        <Flex flex={1} align="flex-end">
          <P>Supernöjd</P>
        </Flex>
      </Flex>

      <Margin $top="7xl" />

      <MainButton onClick={() => mutate()} charityStyles={chosenCharity ?? chosenPublicCharity}>
        Skicka
      </MainButton>
    </MainContentWrapper>
  );
};
