import { ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useChosenPublicCharity } from '../../../traits/context/chosenPublicCharity';

type Props = {
  children: ReactElement | ReactElement[];
};

export const PublicCharityCheck = ({ children }: Props) => {
  const { chosenCharity } = useChosenPublicCharity();
  const { slug } = useParams();
  const navigate = useNavigate();

  if (!chosenCharity) {
    console.error('It seems "chosenCharity" could not be found. Is publicKey present and valid?');

    return <></>;
  }

  if (chosenCharity.slug !== slug) {
    navigate('/404');
  }

  return <>{children}</>;
};
