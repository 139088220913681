import React from 'react';
import styled from 'styled-components';
import { defaultTheme } from '../core/theme';

const CustomRangeInput = styled.input<{ sliderThumbColor: string | undefined }>`
  /*Range Reset*/
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;

  /* Removes default focus */
  &:focus {
    outline: none;
  }

  /***** Chrome, Safari, Opera and Edge Chromium styles *****/
  /* slider track */
  &::-webkit-slider-runnable-track {
    background-color: ${() => defaultTheme.colors.lighterGrey};
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -12px; /* Centers thumb on the track */

    /*custom styles*/
    background-color: ${({ sliderThumbColor }) => sliderThumbColor ?? defaultTheme.colors.mainRed};
    height: 2rem;
    width: 1rem;
  }

  /******** Firefox styles ********/
  /* slider track */
  &::-moz-range-track {
    background-color: ${() => defaultTheme.colors.lighterGrey};
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  &::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 1rem; /*Removes default border-radius that FF applies*/

    /*custom styles*/
    background-color: ${({ sliderThumbColor }) => sliderThumbColor ?? defaultTheme.colors.mainRed};
    height: 1.5rem;
    width: 1.5rem;
  }
`;

type Props = {
  sliderThumbColor: string | undefined;
  min: number;
  max: number;
  value: string;
  onChange: (value: string) => void;
};

export const RangeInput: React.FC<Props> = ({ sliderThumbColor, min, max, value, onChange }) => {
  return (
    <CustomRangeInput
      value={value}
      onChange={(ev: React.FormEvent<HTMLInputElement>) => onChange(ev.currentTarget.value)}
      type="range"
      sliderThumbColor={sliderThumbColor}
      min={min}
      max={max}
    />
  );
};
